import { Dialog } from "primereact/dialog";
import React, { BaseSyntheticEvent, useEffect, useState } from "react";
import { CreateProduct, Product, ProductListItem, attachAllProductsToMerchant, attachProductToMerchant, createProduct, getProducts } from "../../../api/products";
import { Button } from "primereact/button";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Editor } from "primereact/editor";
import { MultiSelect } from "primereact/multiselect";
import { categories } from "../../../helpers/values";

interface OwnProps {
    merchantId: number;
    closeModal: () => void;
    refetch: () => void;
}

const AttachProductModal: React.FC<OwnProps> = ({merchantId, closeModal, refetch}) => {
    const [pagination, setPagination] = useState({page: 1, perPage: 1000, pageCount: 0, total: 0});
    const [createOwn, setCreateOwn] = useState<boolean>(false);
    const [products, setProducts] = useState<ProductListItem[] | null>();
    const [selectedTags, setSelectedTags] = useState<string[]>([]);

    const attachProduct = async (id: string) => {
        await attachProductToMerchant(id, merchantId);
        setProducts(products?.filter(({variantId}) => id !== variantId))
        await refetch();
        closeModal();
    }
    const attachAllProducts = async () => {
        await attachAllProductsToMerchant(merchantId)
        refetch();
        closeModal();
    }

    const initialValues = {
        handle: '',
        title: '',
        vendor: '',
        descriptionHtml: '',
    }
    const {
        control,
        handleSubmit,
        reset
    } = useForm<CreateProduct >({defaultValues: initialValues})

    const onSubmit = async (data: FieldValues, e?: BaseSyntheticEvent) => {
        try {
            await createProduct(merchantId, {...(data as Product), tags: selectedTags});
            setCreateOwn(false);
            reset(initialValues);
        } catch (err) {
            toast.error(err as string);
        }
    };

    useEffect(() => {
        const setProductData = async () => {
            const response = await getProducts({filters: {main: true, merchantId}, pagination});
            if(response) {
                const {data, ...paginationPayload} = response;
                setPagination(paginationPayload);
                setProducts(data);
            }
        }
        setProductData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [merchantId])
    return (<Dialog header={'Produkt hinzufügen'} visible={true} style={{width: '50vw'}} onHide={closeModal}>
        <ul className="divide-y divide-gray-100">
            <div className="flex justify-between gap-x-6 py-5">
                <Button onClick={() => setCreateOwn(!createOwn)} className="border border-midblue text-midblue p-2 hover:bg-midblue hover:text-white">Eigenes Produkt</Button>
                <Button className="border border-midblue text-white hover:text-midblue p-2 bg-midblue hover:bg-transparent" onClick={attachAllProducts}>Alle hinzufügen</Button>
            </div>
            {createOwn ? (
                <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col pt-2 w-full gap-4">
                    <div className="flex justify-between gap-x-6 py-5">
                    <Controller name={"title"} control={control} render={({ field, fieldState }) => (
                        <InputText placeholder='Produktname' id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error }, 'w-full border p-2')} onChange={(e) => field.onChange(e.target.value)} />
                    )} />
                    <Controller name={"vendor"} control={control} render={({ field, fieldState }) => (
                        <InputText placeholder='Hersteller' id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error }, 'w-full border p-2')} onChange={(e) => field.onChange(e.target.value)} />
                    )} />
                    </div>
                    <div className="flex justify-between gap-x-6 py-5">
                        <MultiSelect value={selectedTags} options={categories} onChange={(e) => setSelectedTags(e.target.value)} placeholder="Tags" className="p-2 pl-8 border w-full" />
                        <Controller name={"barcode"} control={control} render={({ field, fieldState }) => (
                            <InputText placeholder='Barcode' id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error }, 'w-full border p-2')} onChange={(e) => field.onChange(e.target.value)} />
                        )} />
                    </div>
                    <div className="flex justify-between gap-x-6 py-5">
                        <Controller name={"price"} control={control} render={({ field, fieldState }) => (
                            <InputNumber placeholder='Preis in Rappen' id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error }, 'w-full border p-2')} onChange={(e) => field.onChange(e.value)} />
                        )} />
                        <Controller name={"compareAtPrice"} control={control} render={({ field, fieldState }) => (
                            <InputNumber placeholder='Vegleichspreis in Rappen' id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error }, 'w-full border p-2')} onChange={(e) => field.onChange(e.value)} />
                        )} />
                    </div>
                <div className="flex justify-between gap-x-6 py-5">
                <Controller name={"descriptionHtml"} control={control} render={({ field, fieldState }) => (
                    <Editor placeholder="Produktbeschreibung" id={field.name}  style={{ height: '220px' }} value={field.value} className={classNames({ 'p-invalid': fieldState.error }, 'w-full')} onTextChange={(e) => field.onChange(e.htmlValue)} />
                )} />
                </div>
                
                <Button label="Speichern" type="submit" icon="pi pi-check" />
            </form>
            ) :
            (<>{products?.map((product) => (
                <li key={product.id} className="flex justify-between gap-x-6 py-5">
                    <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={product.featuredImage} alt="" />
                    <div className="min-w-0 flex-auto">
                        <p className="text-sm font-semibold leading-6 text-gray-900">{product.title}</p>
                        <p className="mt-1 truncate text-xs leading-5 text-gray-500">{product.vendor} - {product.price}</p>
                    </div>
                    <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                    <Button icon="pi pi-plus" onClick={() => attachProduct(product.variantId as unknown as string)} />
                    </div>
                </li>
            ))}
            </>)
            }
        </ul>
    </Dialog>)
}

export default AttachProductModal;