import { Product, ProductListItem, StockLevel, TaxesEnum } from "../api/products"

export function convertIntToPrice(price: number): string {
    return `CHF ${(price / 100).toFixed(2)}`
}

export function convertStockLevelToListItems(stockLevels: StockLevel[]): ProductListItem[] {
    const listItems: ProductListItem[] = [];
    stockLevels.forEach((stockLevel) => {
        const item: ProductListItem = {
            id: stockLevel.id,
            merchantId: `${stockLevel.merchantId}`,
            variantId: `${stockLevel.variantId}`,
            barcode: stockLevel.variant?.barcode || '',
            compareAtPrice: stockLevel.variant?.price || stockLevel.price,
            sku: stockLevel.sku || '',
            handle: stockLevel.variant?.product?.handle || '',
            title: stockLevel.variant?.product?.title || '',
            tags: stockLevel.variant?.product?.tags?.filter((tag) => tag !== 'default' && tag !== 'offer' && tag !== 'excluded' && tag !== 'hidepaypal' && tag !== '') || [],
            taxes: stockLevel.variant?.product?.taxes || TaxesEnum.NORMAL,
            vendor: stockLevel.variant?.product?.vendor || '',
            featuredImage: stockLevel.variant?.product?.featuredImage || '',
            descriptionHtml: stockLevel.variant?.product?.descriptionHtml || '',
            price: stockLevel.price,
            amount: stockLevel.amount,
            targetAmount: stockLevel.targetAmount,
            triggerAmount: stockLevel.triggerAmount,
            targetRelation: stockLevel.targetRelation,
            isActive: stockLevel.isActive,
            
        }
        if(stockLevel?.shopifyProductId) item.shopifyUrl = `${process.env.REACT_SHOPIFY_ADMIN_URL || 'https://admin.shopify.com/store/9d5d77'}/products/${stockLevel?.shopifyProductId?.split('/')[4]}` || '';
        listItems.push(item);
    })
    return listItems;
}

export function convertProductsForVariant(products: Product[]): ProductListItem[] {
    const listItems: ProductListItem[] = []
    products?.forEach((product) => {
        product.variants?.forEach((variant) => {
                listItems.push({
                    id: product.id || 0,
                    isDeleted: product.isDeleted,
                    variantId: `${variant.id}`,
                    barcode: variant.barcode,
                    compareAtPrice: variant.price,
                    sku: `${variant.sku}`,
                    handle: product.handle,
                    title: product.title,
                    tags: product.tags?.filter((tag) => tag !== 'default' && tag !== 'offer' && tag !== 'excluded' && tag !== 'hidepaypal' && tag !== ''),
                    taxes: product.taxes,
                    vendor: product.vendor,
                    featuredImage: product.featuredImage,
                    descriptionHtml: product.descriptionHtml,
                    price: variant.price,
                    amount: variant.inventoryQuantity,
                    b2bPrice: variant.b2bPrice,
                    minOrderQuantity: variant.minOrderQuantity,
                    inventoryQuantity: variant.inventoryQuantity,
                    reservedQuantity: variant.reservedQuantity,
                    packagingQuantity: variant.packagingQuantity,
                    weclappPackagingUnitId: variant.weclappPackagingUnitId,
                    stockLevels: variant.stockLevels,
                });
        }) 
    })
    return listItems;
}